import React from "react";
import "./Team.css";
const Team = () => {
  return (
    <section className="aMuiPaper-root-174 ajss411 aMuiPaper-elevation0-177">
      <div className="aMuiContainer-root-288 aMuiContainer-maxWidthLg-294">
        <div className="aMuiGrid-root-296 ajss412 aMuiGrid-container-297 aMuiGrid-spacing-xs-3-321">
          <div className="aMuiGrid-root-296 aMuiGrid-item-298 aMuiGrid-grid-xs-12-342 aMuiGrid-grid-sm-6-350 aMuiGrid-grid-md-6-364">
            <h2 className="aMuiTypography-root-228 ajss413 aMuiTypography-h2-234 jss170">
              Unveiling the Team Driving Blockchain Evolution.
            </h2>
          </div>
          <div className="aMuiGrid-root-296 aMuiGrid-item-298 aMuiGrid-grid-xs-12-342 aMuiGrid-grid-sm-6-350 aMuiGrid-grid-md-6-364">
            <p className="aMuiTypography-root-228 ajss414 aMuiTypography-body1-230 inter-font">
              At Trush, we operate as a global, diverse team, reflecting the decentralized ethos of our platform. Working tirelessly across time zones, our crew is a unique mix of innovative entrepreneurs, creative minds, and skilled engineers from various cultures and nations. As we lead this technological uprising, we're steadfast in our goal to establish a future that is free, self-directed, and decentralized.
            </p>
            <a className="njss175 button filled btn-apply-ambassador" href="#" style={{ width: 150, "margin-top": "25px" }}>
              Join Us
            </a>
          </div>
        </div>
        {/*
        <div className="aMuiBox-root-258 ajss419 ajss415">
          <img
            className="ajss416"
            src="https://www.ankr.com/_next/static/images/team-9233470d14e2f84414207eabf9ff8cc1.jpg"
            alt="Ankr is democratizing Web3 for everyone to leverage decentralized applications and play an active role in the future internet."
          />
        </div>
        */}
      </div>
    </section>
  );
};

export default Team;
