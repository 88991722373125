import React from "react";
import Footer from "../layout/Footer/Footer";
import Header from "../layout/Header/Header";
import BlogDetail from "../components/blogDetails/BlogDetails";
import Top from "../components/GoTop/GoTop";
const LegalDisclosures = () => {
  return (
    <div>
      <Top />
      <Header />
      <div className="jss6 jss7 desk-cl ">
      <section className="bdMuiPaper-root-174 bdjss164 MuiPaper-elevation0-177">
        <div className="bdMuiContainer-root-227 MuiContainer-maxWidthLg-233">
        <div className="MuiBox-root-235 bdjss236 bdjss165">
            <p>
            <b>General Information</b><br/>
            Trush is a Layer 1 blockchain platform that emphasizes scalability, security, and interoperability. It is EVM compatible and designed for uncompromising efficiency.
            <br/>
            <br/>
            <b>Disclaimer</b><br/>
            The information provided on this website is for general informational purposes only. While we strive to ensure the accuracy and timeliness of the content, Trush makes no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.
            <br/>
            <br/>
            <b>Limitation of Liability</b><br/>
            In no event will Trush, its affiliates, partners, or employees be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.
            <br/>
            <br/>
            <b>External Links</b><br/>
            Through this website, you may be able to link to other websites which are not under the control of Trush. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.
            <br/>
            <br/>
            <b>Intellectual Property</b><br/>
            All intellectual property rights concerning the materials on this website are owned by Trush. Unauthorized use or reproduction of any content from this site is prohibited.
            <br/>
            <br/>
            <b>Changes to This Disclosure</b><br/>
            Trush reserves the right to modify this legal disclosure at any time. Any changes will be posted on this page, and it is your responsibility to review this disclosure periodically.
            </p>
            </div>
        </div>
    </section>
      </div>
      <Footer />
    </div>
  );
};

export default LegalDisclosures;
