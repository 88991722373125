import React, { useEffect, useState } from "react";
import "./Evaluation.css";
import robustProtectionImg from '../../../assets/img/18.png';
import postImg from '../../../assets/img/19.png';
import communityLedImg from '../../../assets/img/20.png';

const Evaluation = () => {
  const [activeContainer1, setactiveContainer1] = useState(true);
  const [activeContainer2, setactiveContainer2] = useState(false);
  const [activeContainer3, setactiveContainer3] = useState(false);
  const [activeToggle, setactiveToggle] = useState(true);

  useEffect(() => {
    let myInter = setInterval(() => {
      if (
        activeContainer1 === true &&
        activeContainer2 === false &&
        activeContainer3 === false
      ) {
        setactiveContainer1(false);
        setactiveContainer2(true);
        setactiveContainer3(false);
      } else if (
        activeContainer1 === false &&
        activeContainer2 === true &&
        activeContainer3 === false
      ) {
        setactiveContainer1(false);
        setactiveContainer2(false);
        setactiveContainer3(true);
      } else if (
        activeContainer1 === false &&
        activeContainer2 === false &&
        activeContainer3 === true
      ) {
        setactiveContainer1(true);

        setactiveContainer2(false);
        setactiveContainer3(false);

        setactiveToggle(false);
        setTimeout(() => {
          setactiveToggle(true);
        }, 200);
      }

      return clearInterval(myInter);
    }, 8100);
    console.log("change");
  }, [activeContainer2, activeContainer1, activeContainer3]);
  return (
    <div className="style__Container-fyg5vl-0 ghLbDN">
      <div className="style__Header-fyg5vl-1 hUSrKw">
        <h1 className="MuiTypography-root-281 jss373 MuiTypography-h1-286">
          Safety First: {/* */}
          <span className="jss374"> Trush Design</span>
        </h1>
        <div className="style__ItemContainer-fyg5vl-4 fFiHgz MuiContainer-root-81 MuiContainer-maxWidthLg-87">
          <div
            className="progress-br"
            style={{
              position: "absolute",

              height: "3px",
              top: "130px",
            }}
          >
            <div className="style__BackLine-fyg5vl-13 fQHfRX" />
            <div
              id="daas_evolution_bg_line"
              className={
                activeToggle
                  ? "style__BgLine-fyg5vl-12 jjcvev"
                  : "style__BgLine-fyg5vl-12 "
              }
            />
          </div>
          <div className="style__Item-fyg5vl-5 zRIGw">
            <div
              id="evolution-itemTitle-1"
              className={
                activeContainer1
                  ? "style__ItemTitle-fyg5vl-6 TPjzn active-boxs"
                  : "style__ItemTitle-fyg5vl-6 TPjzn"
              }
            >
              Security-First
              <br />
              Design
            </div>
            <div
              id="evolution-line-1"
              className="style__Line-fyg5vl-7 gXQHOr"
            />
            <div
              id="evolution-info-container-1 "
              className={
                activeContainer1
                  ? "style__Info-fyg5vl-8 ioiuNB  active-boxs-f"
                  : "style__Info-fyg5vl-8 ioiuNB  "
              }
            >
              <img
                src={robustProtectionImg}
                alt="All-In-One Centralized Custody"
                className="style__Img-fyg5vl-9 kVAVax"
              />
              <div className="style__Main-fyg5vl-10 gNunOz jss170">
                Robust Protection Priority
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Decentralization & Validator Selection
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Security Protocols & Cryptography
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Intrusion Detection & Monitoring
              </div>
            </div>
          </div>
          <div className="style__Item-fyg5vl-5 zRIGw">
            <div
              id="evolution-itemTitle-2"
              className={
                activeContainer2
                  ? "style__ItemTitle-fyg5vl-6 TPjzn active-boxs"
                  : "style__ItemTitle-fyg5vl-6 TPjzn"
              }
            >
              Unique Security
              <br />
              Measures
            </div>
            <div
              id="evolution-line-2"
              className="style__Line-fyg5vl-7 gXQHOr"
            />
            <div
              id="evolution-info-container-2"
              style={{
                margin: "0px auto",
              }}
              className={
                activeContainer2
                  ? "style__Info-fyg5vl-8 ioiuNB  active-boxs-f"
                  : "style__Info-fyg5vl-8 ioiuNB  "
              }
            >
              <img
                src={postImg}
                alt="Smart Contract Based Custody"
                className="style__Img-fyg5vl-9 kVAVax"
              />
              <div className="style__Main-fyg5vl-10 gNunOz jss170">
                PoS & Refined PoH Blend
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Layered Security Framework
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Audits & Bug Bounties
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Finality & Slashing Rule
              </div>
            </div>
          </div>
          <div className="style__Item-fyg5vl-5 zRIGw">
            <div
              id="evolution-itemTitle-3"
              className={
                activeContainer3
                  ? "style__ItemTitle-fyg5vl-6 TPjzn active-boxs"
                  : "style__ItemTitle-fyg5vl-6 TPjzn"
              }
            >
              Future-Proof
              <br />
              Innovation
            </div>
            <div
              id="evolution-line-3"
              className="style__Line-fyg5vl-7 gXQHOr"
            />
            <div
              id="evolution-info-container-3"
              style={{
                marginLeft: "auto",
              }}
              className={
                activeContainer3
                  ? "style__Info-fyg5vl-8 ioiuNB  active-boxs-f"
                  : "style__Info-fyg5vl-8 ioiuNB  "
              }
            >
              <img
                src={communityLedImg}
                alt="App Chain Based Cross-Chain Custody"
                className="style__Img-fyg5vl-9 kVAVax"
              />
              <div className="style__Main-fyg5vl-10 gNunOz jss170">
                Community-Led Security
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Decentralized Governance
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Backup Nodes & Incentives
              </div>
              <div className="style__Sub-fyg5vl-11 rAyIF">
                Adoption of Advanced Practices
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Evaluation;
