import React from "react";
import "./Program.css";
const Program = () => {
  return (
    <section className="MuiPaper-root-298 bjss289 MuiPaper-elevation0-301">
      <div className="MuiContainer-root-351 MuiContainer-maxWidthLg-357">
        <h2 className="MuiTypography-root-359 bjss290 MuiTypography-h2-365 jss170">
          Join Trush's Ambassador program
        </h2>
        <div className="MuiGrid-root-389 bjss291 MuiGrid-container-390">
          <div className="MuiGrid-root-389 MuiGrid-item-391 MuiGrid-grid-xs-12-435 MuiGrid-grid-sm-6-443">
            <div className="MuiBox-root-492 bjss494 bjss294">
              <h5 className="MuiTypography-root-359 bjss295 MuiTypography-h5-368 font-weight-bold">
                <i>We are seeking passionate ambassadors to promote our vision and mission in the greater blockchain ecosystem.</i>
              </h5>
              <p className="MuiTypography-root-359 bjss296 MuiTypography-body1-361">
                Our goal is to transform the blockchain realm with cutting-edge tech and a user-centric approach. With key features such as Trush Beacon Consensus and Ethereum compatibility, we aspire to elevate blockchain usability and speed. 
              </p>
              <p className="MuiTypography-root-359 bjss296 MuiTypography-body1-361">
                As part of the Trush Ambassador Program, you'll be endorsing a platform that highly values innovation, efficiency, and the principles of decentralization. Join us in our mission to make Trush the primary choice for developers and users around the globe.
              </p>  
              <a className="njss175 button filled btn-join-program" href="#" style={{ width: 150, "margin-top": 30 }}>
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Program;
