import React from "react";
import "./BlockChain.css";
import img1 from '../../../assets/img/21.png';
import img2 from '../../../assets/img/22.jpg';
import img3 from '../../../assets/img/23.png';
import img4 from '../../../assets/img/24.jpg';
import img5 from '../../../assets/img/25.png';
import img6 from '../../../assets/img/26.jpg';

const BlockChain = () => {
  return (
    <section
      className="MuiPaper-root-179 jss339 MuiPaper-elevation0-181"
      style={{ padding: "75px 0 75px 0", "margin-top": "70px" }}
    >
      <div className="MuiContainer-root-271 jss340 MuiContainer-maxWidthLg-277 no-minpad">
        <div className="jss341 jss279 jss280">
          <h1 className="MuiTypography-root-281 jss373 MuiTypography-h1-286">
            Pioneering the New Era of {/* */}
            <span className="jss374"> Chain Connectivity</span>
          </h1>
          <p className="MuiTypography-root-281 jss344 MuiTypography-body1-283 opacity-sub">
            Fostering Innovation, Liquidity, and Usability Across Platforms with Our Groundbreaking Solutions
          </p>
        </div>
        <div className="c-gqwkJN tc-pade c-gqwkJN-fGHEql-fullWidth-true c-gqwkJN-iTKOFX-direction-column c-gqwkJN-jroWjL-align-center c-gqwkJN-bICGYT-justify-center c-gqwkJN-kVNAnR-wrap-noWrap">
          <div className="c-PJLV c-ccUAhX">
            <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-igtkwp-align-end c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-csPmrL c-csPmrL-cSVyQl-variant-full">
              <div className="c-gqwkJN c-gqwkJN-iTKOFX-direction-column c-gqwkJN-JrrAq-align-start c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-kKcGaR">
                <div className="c-PJLV c-fgJEww" style={{background: 'none', padding: 0}}>
                  <img src={img1} />
                </div>
                <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-jroWjL-align-center c-gqwkJN-bICGYT-justify-center c-gqwkJN-kVNAnR-wrap-noWrap c-gqwkJN-fVlWzK-gap-3 c-gqwkJN-ejLhMq-mb-4">
                  <div className="words-section_title__Pb56o jss170">
                    Unifying Blockchain Horizons
                  </div>
                </div>
                <p className="c-khxPPA c-khxPPA-ifHAhc-css">
                  Trush bridges blockchain platforms for smooth interactions, fostering innovation, decentralization, and an enhanced user experience.
                </p>
              </div>
              <div className="c-PJLV c-cQsYws c-cQsYws-cFMLhj-variant-full">
                {/* <img
                  alt="Integrate with protocols seamlessly"
                  sizes="100vw"
                 src={img2}
                  decoding="async"
                  data-nimg="fill"
                  loading="lazy"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    objectFit: "cover",
                    color: "transparent",
                  }}
                /> */}
              </div>
            </div>
            <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-igtkwp-align-end c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-csPmrL c-csPmrL-bOzIcj-variant-half" style={{height: 'auto'}}>
              <div className="c-PJLV c-cQsYws c-cQsYws-cqGezO-variant-half" style={{height: 'auto', marginTop: 'var(--space-6)'}}>
                {/* <img
                  alt="Simplify complex interactions"
                  src={img4}
                  decoding="async"
                  data-nimg="fill"
                  loading="lazy"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    objectFit: "contain",
                    color: "transparent",
                  }}
                /> */}
              </div>
              <div className="c-gqwkJN c-gqwkJN-iTKOFX-direction-column c-gqwkJN-JrrAq-align-start c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-kKcGaR c-kKcGaR-eYmcvs-variant-half">
                <div className="c-PJLV c-fgJEww" style={{background: 'none', padding: 0}}>
                  <img src={img3} />
                </div>
                <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-jroWjL-align-center c-gqwkJN-bICGYT-justify-center c-gqwkJN-kVNAnR-wrap-noWrap c-gqwkJN-fVlWzK-gap-3 c-gqwkJN-ejLhMq-mb-4">
                  <div className="words-section_title__Pb56o jss170">
                    Trustless Interoperability Protocol (TIP)
                  </div>
                </div>
                <p className="c-khxPPA c-khxPPA-ifHAhc-css">
                  Enables trustless cross-chain communication, elevating liquidity, and facilitating seamless smart contract integration across networks, all while enriching user experience and expanding the Trush ecosystem.
                </p>
              </div>
            </div>
            <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-igtkwp-align-end c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-csPmrL c-csPmrL-bOzIcj-variant-half" style={{height: 'auto'}}>
              <div className="c-PJLV c-cQsYws c-cQsYws-jmiiOG-isLast-true c-cQsYws-cqGezO-variant-half" style={{height: 'auto', marginTop: 'var(--space-6)'}}>
                {/* <img
                  alt="Optimise costs at every step"
                  src={img6}
                  decoding="async"
                  data-nimg="fill"
                  loading="lazy"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "100%",
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    objectFit: "cover",
                    color: "transparent",
                  }}
                /> */}
              </div>
              <div className="c-gqwkJN c-gqwkJN-iTKOFX-direction-column c-gqwkJN-JrrAq-align-start c-gqwkJN-awKDG-justify-start c-gqwkJN-kVNAnR-wrap-noWrap c-kKcGaR c-kKcGaR-eYmcvs-variant-half">
                <div className="c-PJLV c-fgJEww" style={{background: 'none', padding: 0}}>
                  <img src={img5} />
                </div>
                <div className="c-gqwkJN c-gqwkJN-ejCoEP-direction-row c-gqwkJN-jroWjL-align-center c-gqwkJN-bICGYT-justify-center c-gqwkJN-kVNAnR-wrap-noWrap c-gqwkJN-fVlWzK-gap-3 c-gqwkJN-ejLhMq-mb-4">
                  <div className="words-section_title__Pb56o jss170">
                     Trush Universal Gateway (TUG)
                  </div>
                </div>
                <p className="c-khxPPA c-khxPPA-ifHAhc-css">
                   Serves as a cutting-edge cross-chain bridge, enabling seamless asset transfers across diverse blockchains, thereby establishing Trush as a universal hub for blockchain interoperability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlockChain;
