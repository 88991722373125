import React from "react";
import "./Become.css";
const Become = () => {
  return (
    <section className="MuiPaper-root-532 bjss523 MuiPaper-elevation0-535">
      <div className="MuiContainer-root-585 MuiContainer-maxWidthLg-591">
        <h2 className="MuiTypography-root-593 bjss524 MuiTypography-h2-599 jss170">
          Who can become a Trush Ambassador?
        </h2>
        <div className="MuiGrid-root-623 bjss525 MuiGrid-container-624 MuiGrid-spacing-xs-2-647">
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss727 bjss526">
              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Developers
              </h5>
            </div>
          </div>
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss728 bjss526">

              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Crypto Enthusiasts
              </h5>
            </div>
          </div>
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss729 bjss526">

              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Content Creators
              </h5>
            </div>
          </div>
                    <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss727 bjss526">

              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Community Leaders
              </h5>
            </div>
          </div>
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss728 bjss526">

              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Blockchain Researchers
              </h5>
            </div>
          </div>
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669 MuiGrid-grid-md-4-689">
            <div className="MuiBox-root-726 bjss729 bjss526">

              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602 jss170">
                Translators
              </h5>
            </div>
          </div>
          <div className="MuiGrid-root-623 MuiGrid-item-625 MuiGrid-grid-xs-12-669">
            <div className="MuiBox-root-726 bjss730 bjss529">
              <h5 className="MuiTypography-root-593 bjss530 MuiTypography-h5-602">
                ..And all others are welcome to apply!
              </h5>
              <p className="MuiTypography-root-593 bjss531 MuiTypography-body1-595">
                Whether you're a developer, crypto enthusiast, content creator, community leader, educator, researcher, translator, or just someone passionate about Trush and decentralization, we welcome you! {/*We need passionate individuals, active in the blockchain community, who can help us spread the word about Trush and our vision for a more accessible and efficient blockchain future.*/}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Become;
