import React from "react";
import "./Banner.css";
import cointele from "../../../assets/img/cointelegraphy.png";
import forbes from "../../../assets/img/forbes.png";
import binance from "../../../assets/img/binance.png";
import business from "../../../assets/img/business.png";
import nasdaq from "../../../assets/img/nasdaq.png";
import coinmarket from "../../../assets/img/coinmarketcap.png";

const Banner = () => {
  return (
    <section className="aMuiPaper-root-174 ajss164 aMuiPaper-elevation0-177 banner-sprinkle">
      <div className="aMuiBox-root-258 ajss259 ajss165">
        <h2 className="aMuiTypography-root-228 ajss166 aMuiTypography-h2-234">
          <span>
            <span>Creating a More Connected,</span>{" "}
            <span className="jss170">Decentralized Future.</span>
          </span>
        </h2>
        <h4 className="aMuiTypography-root-228 ajss167 aMuiTypography-h4-236">
          <span>
            <span className="learn-text">Trush is driving change. Come, be part of our innovation.</span>{" "}
          </span>
        </h4>
        <a className="njss175 button filled btn-center" href="#">
          See Job Openings
        </a>
        <img
          className="ajss168"
          src="https://www.ankr.com/_next/static/images/arrow-mobile-575056796ea8f072c0059ca3aa5a077e.png"
          alt="arrow"
        />
      </div>
      <div className="aMuiContainer-root-288 ajss171 aMuiContainer-maxWidthLg-294">
        
      </div>
    </section>
  );
};

export default Banner;
